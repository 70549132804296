jQuery(document).ready(function($) {

	// store the slider in a local variable
	let $window = $(window);
	let flexslider = { vars:{} };

	// tiny helper function to add breakpoints
	function getGridSize() {
		if(window.innerWidth < 768){
			return 1;
		}else if(window.innerWidth < 1024){
			return 2;	
		
		}else{
			return 3;
		}
	}
	
	$window.load(function() {
		if ( $('.events-slider .flexslider').length ) {
			// Flexslider
			$('.events-slider .flexslider').flexslider({
				animation: "slide",
				controlNav: false,
				itemMargin: 34,
				itemWidth: 337,
				maxItems: getGridSize(),
				minItems: getGridSize(),
				nextText: '<span class="screen-reader-text">Next</span>',
				prevText: '<span class="screen-reader-text">Previous</span>',
				slideshow: false,
				start: function(slider){
					//set slider instance to flexslider variable
					flexslider = slider;
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
				after: function(slider){
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
			});
		}
	});

	// check grid size on resize event
	$window.resize(function() {
		var gridSize = getGridSize();
		flexslider.vars.minItems = gridSize;
		flexslider.vars.maxItems = gridSize;
	});

}); /* end of as page load scripts */